import {request} from './request'
import baseUrlObj from "./baseURL"
const systemNumber = baseUrlObj.systemNumber

// 获取专家列表 /ewi/guest/Expert/PageExpertList
export function pageExpertList(isHome,page,size,typeId,expertName){
  return request({
    url: '/ewi/guest/Expert/PageExpertList',
    method: 'post',
    data: {
      isHome,
      page,
      size,
      typeId,
      expertName,
      systemNumber: systemNumber,
    }
  })
}

// 获取单个专家信息 /ewi/guest/Expert/GetExpertInfo
export function getExpertInfo(expertNumber){
  return request({
    url: '/ewi/guest/Expert/GetExpertInfo',
    method: 'get',
    params: {
      expertNumber,
      systemNumber: systemNumber,
    }
  })
}
